import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getDirectoriesScanService } from 'services/scanning';
import FileTreeScanModalComponent from '../components/FileTreeScanModalComponent';

const FileTreeScanModal = (props) => {
    const { isOpen, setIsOpen, selectFileCallback, settings } = props;
    const [isFetching, setIsFetching] = useState([]);
    const [treeData, setTreeData] = useState([]);

    useEffect(() => {
        getFoldersTreeData();
    }, []);

    const fromFlatToTree = (items, id = 0, link = 'parentId') => {
        return items
            .filter(item => item[link] === id)
            .map(item => ({
                key: item.directoryId,
                title: item.directoryName,
                children: fromFlatToTree(items, item.directoryId),
                fullName: item.fullName
            }));
    }

    const getFoldersTreeData = async () => {
        setIsFetching(true);

        try {
            const response = await getDirectoriesScanService();
            const treeItems = fromFlatToTree(response);
            console.log(treeItems)
            setTreeData(treeItems);
        } catch (err) {
            console.log(err);
        }

        setIsFetching(false);
    }

    return (
        <FileTreeScanModalComponent
            isFetching={isFetching}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            settings={settings}
            treeData={treeData}
            selectFileCallback={selectFileCallback}
        />
    )
};

const mapStateToProps = (store) => ({
    settings: store.settingsReducer.settings,
});

export default connect(mapStateToProps)(FileTreeScanModal);
